<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="desserts"
      class="elevation-1"
      dense
      mobile-breakpoint="400"
      :search="name"
    >
      <template #top>
        <v-container fluid>
          <v-row
            ><v-spacer></v-spacer>
            <table>
              <tr>
                <td width="300px">
                  <v-text-field
                    v-model="name"
                    outlined
                    hide-details
                    label="Nome do treino"
                    dense
                    success
                  />
                </td>
                <td>
                  <v-avatar tile>
                    <v-img :src="treinoChanged"></v-img>
                  </v-avatar>
                </td>
                <td>
                  <v-btn icon title="Carregar gif">
                    <label for="upload">
                      <v-icon>mdi-paperclip</v-icon>
                      <input
                        type="file"
                        id="upload"
                        ref="upload"
                        style="display: none"
                        @change="changeGif"
                      />
                    </label>
                  </v-btn>
                </td>
                <td>
                  <v-btn color="green" :disabled="!name" @click="create" :loading="loading"
                    >salvar</v-btn
                  >
                </td>
              </tr>
            </table>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </template>
      <template #item.gif="{ item }">
        <v-avatar tile>
          <v-img :src="item.gif"></v-img>
        </v-avatar>
      </template>
      <template #item.actions="{ item }">
        <table>
          <tr>
            <td class="pa-2">
              <v-icon @click="editItem(item)"> mdi-pencil-outline </v-icon>
            </td>
            <td class="pa-2">
              <v-icon @click="deleteItem(item)"> mdi-delete-outline </v-icon>
            </td>
          </tr>
        </table>
      </template>
    </v-data-table>
    <Alerts
      :text="alertMessage"
      :type="alertType"
      v-if="alertMessage"
      @close="alertMessage = false"
      @confirm="confirmDelete"
    />
  </v-container>
</template>
<script>
import Alerts from "../Snackbar/Snackbar.vue";
export default {
  components: { Alerts },
  data() {
    return {
      loading: false,
      editedItem: null,
      treinoChanged: "",
      alertMessage: "",
      alertType: "",
      name: "",
      gif: "",
      headers: [
        {
          text: "Treino",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Modelo", value: "gif", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      desserts: [],
    };
  },
  methods: {
    editItem(item){
      this.editedItem = item
      this.name = item.name
      this.treinoChanged = item.gif
    },
    deleteItem(item) {
      this.editedItem = item
      this.alertType = 'confirm'
      this.alertMessage = 'Tem erteza que deseja deletar esste treino?'
    },
    confirmDelete(){
      const index = this.desserts.indexOf(this.editedItem)
      this.$store.commit('setLoading', true)
      this.$store.state.axios.delete('/workouts/'+this.editedItem._id)
        .then(() => {
          this.$store.commit('setLoading', false)
          this.alertType = 'success'
          this.alertMessage = 'Treino removido com sucesso!'
          this.desserts.splice(index, 1)
        })
        .catch(error => {
          this.$store.commit('setLoading', false)
          this.alertType = 'error'
          this.alertMessage =
            ((error.response || {}).data || {}).error ||
            error.stack +
              "\r\n" +
              "stack: " +
              ((error.response || {}).dada || {}).stack ||
            "";
          
        })
      
    },
    changeGif(e) {
      this.treinoChanged = e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : '';
    },
    update(){
      if(this.name == this.editedItem.name && this.treinoChanged == this.editedItem.gif){
        this.editedItem = null
        this.name = ''
        this.treinoChanged = ''
        return
      }
      const formData = new FormData()
      formData.append('name', this.name)
      formData.append('gif', this.$refs.upload.files[0])
      formData.append('_id', this.editedItem._id)
      this.loading = true
      this.$store.state.axios.put('/workouts', formData)
        .then(({data}) => {
          this.loading = false
          this.alertType = 'success'
          this.alertMessage = 'Treino alterado com sucesso!'
          const index = this.desserts.indexOf(this.editedItem)
          this.editedItem = null
          this.name= ""
          this.treinoChanged = ''
          this.desserts.splice(index, 1)
          this.desserts.push(data)
        })
        .catch(error => {
          this.loading = false
          this.alertType = 'error'
          this.alertMessage =
            ((error.response || {}).data || {}).error ||
            error.stack +
              "\r\n" +
              "stack: " +
              ((error.response || {}).dada || {}).stack ||
            "";
        })
    },
    async create() {
      if(this.editedItem) return this.update()
      const find = this.desserts.find((item) => item.name == this.name);
      if (find) {
        this.alertType = "error";
        this.alertMessage = "Já existe um treino com esse nome";
        this.$refs.upload.value = null
        return;
      }
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("gif", this.$refs.upload.files[0]);
      this.loading = true
      await this.$store.state.axios
        .post(this.$store.state.baseUrl + "/workouts", formData)
        .then(({ data }) => {
          this.alertType = "success";
          this.alertMessage = 'Treino salvo com sucesso!';
          this.desserts.push(data);
        })
        .catch((err) => {
          this.alertType = "error";
          this.alertMessage =
            ((err.response || {}).data || {}).error ||
            err.stack +
              "\r\n" +
              "stack: " +
              ((err.response || {}).dada || {}).stack ||
            "";
        });
        this.loading = false
      this.$refs.upload.value = null
    },
    showGif(gif) {
      this.$store.commit("modals/setUrlGif", gif);
      this.$store.commit("modals/setShowGif", true);
    },
  },
  async mounted() {
    this.desserts = this.$store.state.treinos.allTreinos.length ? this.$store.state.treinos.allTreinos : await this.$store.state.axios
      .get("/workouts")
      .then(({ data }) => data)
      .catch((err) => {
        this.alertType = "error";
        this.alertMessage =
          ((err.response || {}).data || {}).error ||
          err.stack +
            "\r\n" +
            "stack: " +
            ((err.response || {}).dada || {}).stack ||
          "";
        return [];
      });
  },
  beforeDestroy(){
    this.$store.commit('treinos/setAllTreinos', this.desserts)
  }
};
</script>